<template>
    <div class="main-container">
      <div>
        <h1 class="main-title">관리자 홈</h1>
        <br>

        <mdb-container class="digital-clock">
        <p class="date-time">
          <span class="date">{{ currentDate }}</span>
          <span class="time">{{ currentTime }}</span>
        </p>
        </mdb-container>
  
        <mdb-container class="weather-block">
          <WeatherWidget /> 
        </mdb-container>
  
        <!-- <mdb-container class="operation-alert">
  <br>
  <h3 class="alert-title">교육원 기체 운용 알림</h3>
  <br>
  <mdb-row>
    
    <mdb-col>
      <h5 class="aircraft-col">기체</h5>
      <div v-for="(aircraft, index) in aircraftData" :key="aircraft.name">
        <p class="aircraft-name">{{ aircraft.name }}</p>
        
        <div class="horizontal-divider" v-if="index < aircraftData.length - 1"></div>
      </div>
    </mdb-col>

    
    <mdb-col>
      <h5 class="other-col">보험</h5>
      <div v-for="aircraft in aircraftData" :key="aircraft.name + '-insurance'">
        <p class="progress-text">보험 만기일</p>
        <p
          class="progress-text"
          :class="{ 'warning-text': (aircraft?.insurance?.daysRemaining ?? Infinity) <= warnLimits.insurance }"
        >
          D-{{ aircraft?.insurance?.daysRemaining ?? '정보 없음' }}
          <img
            v-if="(aircraft?.insurance?.daysRemaining ?? Infinity) <= warnLimits.insurance"
            src="@/assets/images/warning-icon.png"
            alt="Warning Icon"
            class="warning-icon"
          />
        </p>
        <mdb-progress
          :value="+(aircraft?.insurance?.progress ?? 0)"
          :max="100"
          class="progress-bar"
        ></mdb-progress>
      </div>
    </mdb-col>

   
    <mdb-col>
      <h5 class="other-col1">안정성 인증검사</h5>
      <div v-for="aircraft in aircraftData" :key="aircraft.name + '-inspection'">
        <p class="progress-text">검사 만기일</p>
        <p
          class="progress-text"
          :class="{ 'warning-text': (aircraft?.inspection?.daysRemaining ?? Infinity) <= warnLimits.inspection }"
        >
          D-{{ aircraft?.inspection?.daysRemaining ?? '정보 없음' }}
          <img
            v-if="(aircraft?.inspection?.daysRemaining ?? Infinity) <= warnLimits.inspection"
            src="@/assets/images/warning-icon.png"
            alt="Warning Icon"
            class="warning-icon"
          />
        </p>
        <mdb-progress
          :value="+(aircraft?.inspection?.progress ?? 0)"
          :max="100"
          class="progress-bar"
        ></mdb-progress>
      </div>
    </mdb-col>

   
    <mdb-col>
      <h5 class="other-col2">비행 승인</h5>
      <div v-for="aircraft in aircraftData" :key="aircraft.name + '-flightApproval'">
        <p class="progress-text">승인 만기일</p>
        <p
          class="progress-text"
          :class="{ 'warning-text': (aircraft?.flightApproval?.daysRemaining ?? Infinity) <= warnLimits.flightApproval }"
        >
          D-{{ aircraft?.flightApproval?.daysRemaining ?? '정보 없음' }}
          <img
            v-if="(aircraft?.flightApproval?.daysRemaining ?? Infinity) <= warnLimits.flightApproval"
            src="@/assets/images/warning-icon.png"
            alt="Warning Icon"
            class="warning-icon"
          />
        </p>
        <mdb-progress
          :value="+(aircraft?.flightApproval?.progress ?? 0)"
          :max="100"
          class="progress-bar"
        ></mdb-progress>
      </div>
    </mdb-col>
  </mdb-row>
</mdb-container> -->


<mdb-container class="operation-alert">
    <br />
    <h3 class="alert-title">교육원 기체 운용 알림</h3>
    <br />
<!-- 하나의 grid-row로 헤더 + 반복 행 모두 묶기 -->
<div class="aircraft-grid">
  <!-- 헤더 -->
  <div class="aircraft-row header-row">
    <div class="cell aircraft-col">기체</div>
    <div class="cell other-col">보험</div>
    <div class="cell other-col1">안정성 인증검사</div>
    <div class="cell other-col2">비행 승인</div>
  </div>

  <!-- 데이터 행 -->
  <div
    v-for="(aircraft) in aircraftData"
    :key="aircraft.name"
    class="aircraft-row"
  >
    <!-- 기체명 -->
    <div class="cell aircraft-name">{{ aircraft.name }}</div>

    <!-- 보험 -->
    <div class="cell">
      <p class="progress-text">보험 만기일</p>
      <p class="progress-text" :class="{ 'warning-text': (aircraft?.insurance?.daysRemaining ?? Infinity) <= warnLimits.insurance }">
        D-{{ aircraft?.insurance?.daysRemaining ?? '정보 없음' }}
      </p>
      <mdb-progress :value="+(aircraft?.insurance?.progress ?? 0)" :max="100" class="progress-bar"></mdb-progress>
    </div>

    <!-- 안정성 인증 -->
    <div class="cell">
      <p class="progress-text">검사 만기일</p>
      <p class="progress-text" :class="{ 'warning-text': (aircraft?.inspection?.daysRemaining ?? Infinity) <= warnLimits.inspection }">
        D-{{ aircraft?.inspection?.daysRemaining ?? '정보 없음' }}
      </p>
      <mdb-progress :value="+(aircraft?.inspection?.progress ?? 0)" :max="100" class="progress-bar"></mdb-progress>
    </div>

    <!-- 비행 승인 -->
    <div class="cell">
      <p class="progress-text">승인 만기일</p>
      <p class="progress-text" :class="{ 'warning-text': (aircraft?.flightApproval?.daysRemaining ?? Infinity) <= warnLimits.flightApproval }">
        D-{{ aircraft?.flightApproval?.daysRemaining ?? '정보 없음' }}
      </p>
      <mdb-progress :value="+(aircraft?.flightApproval?.progress ?? 0)" :max="100" class="progress-bar"></mdb-progress>
    </div>
  </div>
</div>

  </mdb-container>

        <!-- 교육원 운영 지표 섹션 -->
        <mdb-container class="operation-indicators">
            <h3 class="indicator-title">교육원 운영 지표</h3>
            <mdb-row>
                <mdb-col class="indicator-item">
                    <img src="@/assets/images/student.png" alt="학생 아이콘" class="indicator-icon">
                    <div class="text-container">
                    <p class="indicator-label">어제 수강생 수</p>
                    <p class="indicator-value">{{ indicators.yesterdayStudents }}명</p>
                    </div>
                </mdb-col>
                <mdb-col class="indicator-item">
                    <img src="@/assets/images/flight-time.png" alt="비행 시간 아이콘" class="indicator-icon">
                    <div class="text-container">
                    <p class="indicator-label">어제 총 비행시간</p>
                    <p class="indicator-value">{{ indicators.yesterdayFlightTime }}h</p>
                    </div>
                </mdb-col>
              </mdb-row>
              <mdb-row>
                <mdb-col class="indicator-item">
                    <img src="@/assets/images/last-month-students.png" alt="전월 수강생 수 아이콘" class="indicator-icon">
                    <div class="text-container">
                    <p class="indicator-label">전월 수강생 수</p>
                    <p class="indicator-value">{{ indicators.lastMonthStudents }}명</p>
                    </div>
                </mdb-col>
                <mdb-col class="indicator-item">
                    <img src="@/assets/images/last-month-flight-times.png" alt="전월 총비행시간 아이콘" class="indicator-icon">
                    <div class="text-container">
                    <p class="indicator-label">전월 총 비행시간</p>
                    <p class="indicator-value">{{ indicators.lastMonthFlightTime }}h</p>
                    </div>
                </mdb-col>
            </mdb-row>
        </mdb-container>
        <mdb-container class="calendar">
          <h3 class="indicator-title">교육원 전체 일정</h3>
          <!-- FullCalendar 컴포넌트 추가 -->
          <div class="calendar-container">
            <full-calendar
              :plugins="calendarPlugins"
              :initial-view="'dayGridMonth'"
              :events="events"
              :header="{
                left: '',
                center: 'title',
                right: 'prev next'
              }"   
              :displayEventTime="false"           
              @eventClick="goToDetailedCalendar" />
          </div>
      </mdb-container> 
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from "@/axios";
  import { mdbContainer, mdbRow, mdbCol, mdbProgress } from "mdbvue";
  import Swal from 'sweetalert2';
  import FullCalendar from '@fullcalendar/vue';
  // eslint-disable-next-line no-unused-vars
  import dayGridPlugin from '@fullcalendar/daygrid'
  import WeatherWidget from "@/components/WeatherWidget.vue";
  
  export default {
    name: 'home',
    components: {
      mdbContainer,
      mdbRow,
      mdbCol,
      mdbProgress,
      FullCalendar,
      WeatherWidget,
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        userInfo: {},
        currentDate: new Date(),
        currentTime: new Date().toLocaleString(),
        
        indicators: {
          yesterdayStudents: 0,
          yesterdayFlightTime: 0,
          lastMonthStudents: 0,
          lastMonthFlightTime: 0,
        },
        events: [],
        calendarPlugins: [dayGridPlugin], 
        aircraftData: [],
        warnLimits: {
          insurance: parseInt(process.env.VUE_APP_WARN_LIMIT_INSURANCE, 10) || 10,
          inspection: parseInt(process.env.VUE_APP_WARN_LIMIT_INSPECTION, 10) || 14,
          flightApproval: parseInt(process.env.VUE_APP_WARN_LIMIT_FLIGHT_APPROVAL, 10) || 7,
        },
      };
    },
    mounted() {
      // 컴포넌트가 로드되면 데이터 가져오기
      this.fetchAircraftData();
      this.fetchEvents();
    },
    created() {
      this.init();      
      
      setInterval(() => {
        this.currentDate = this.formatDate(new Date());
        this.currentTime = this.formatTime(new Date());
      }, 1000);

      this.fetchIndicators();
    },
    methods: {
    init() {
        this.getUserInfo();
    },
    
    async fetchEvents() {
      const tokenData = JSON.parse(sessionStorage.getItem('token'));
      const token = tokenData ? tokenData.access_token : ''; 

      if (!token) {
        Swal.fire('오류', '인증 토큰이 없습니다. 다시 로그인해주세요.', 'error');
        return;
      }

      const config = {
        method: 'get',
        url: '/calendar',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios(config);
        const calendars = response.data;

        this.events = calendars.map((calendar) => {
          const smsEnabled = calendar.sendYN;
          const formattedTime = this.formatKoreanTime(calendar.start_time);

          return {
            id: calendar.uuid,
            //title: smsEnabled ? `✉️ ${calendar.subject}` : calendar.subject, // ✅ Add SMS icon
            title: `${formattedTime} ${calendar.subject} ${smsEnabled ? '✉️' : ''}`,
            start: calendar.start_time,
            end: calendar.end_time,
            backgroundColor: calendar.backgroundColor || '#2396f2', // ✅ Ensure color consistency
            textColor: '#ffffff', // ✅ Ensure white text color
            extendedProps: {
              sms: smsEnabled,
              sendTime: calendar.send_time,
              templateID: calendar.templateID || '',
              adminUUID: calendar.admin_UUID || '',
            },
          };
        });

        console.log('✅ Mapped events for home:', this.events);
      } catch (error) {
        Swal.fire('오류', '일정 조회 중 오류가 발생했습니다.', 'error');
        console.error('일정 조회 오류:', error);
      }
    },

    fetchAircraftData() {
      const config = {
        method: 'get',
        url: '/alarm', // 백엔드의 알맞은 엔드포인트
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`,
        },
      };

      axios(config)
        .then((response) => {
          const { insurance, safetyCert, flightApproval } = response.data;

          // 데이터를 가공하여 aircraftData 생성
          this.aircraftData = this.processAircraftData(
            insurance,
            safetyCert,
            flightApproval
          );
        })
        .catch((error) => {
          console.error('항공기 데이터를 가져오는 데 실패했습니다:', error);
        });
    },

    processAircraftData(insuranceData, safetyCertData, flightAppvData) {
      // 데이터 유효성 확인
      if (!insuranceData || !safetyCertData || !flightAppvData) {
        console.error('필수 데이터가 없습니다.', { insuranceData, safetyCertData, flightAppvData });
        return [];
      }

      const aircraftMap = new Map();

      // 보험 데이터 처리
      insuranceData.forEach((item) => {
        const model = item.model || 'Unknown';
        const expireDate = new Date(item.expire_date);
        const createdAt = new Date(item.createdAt);
        let   totalDays = Math.round((expireDate - createdAt) / (1000 * 60 * 60 * 24));
        let   remainingDays = Math.round((expireDate - new Date()) / (1000 * 60 * 60 * 24));
        let   progress = Number((((totalDays - remainingDays) / totalDays) * 100).toFixed(1));

        if (expireDate.getFullYear()===1970)
        {
          totalDays =null; remainingDays=null; progress=0;
        }
        aircraftMap.set(model, {
          name: model,
          insurance: { daysRemaining: remainingDays, progress },
        });
      });

      // 안정성 인증 데이터 처리
      safetyCertData.forEach((item) => {
        const model = item.model || 'Unknown';
        const expireDate = new Date(item.expire_date);
        const createdAt = new Date(item.createdAt);
        let totalDays = Math.round((expireDate - createdAt) / (1000 * 60 * 60 * 24));
        let remainingDays = Math.round((expireDate - new Date()) / (1000 * 60 * 60 * 24));
        let progress = Number((((totalDays - remainingDays) / totalDays) * 100).toFixed(1));

        if (expireDate.getFullYear()===1970)
        {
          totalDays =null; remainingDays=null; progress=0;
        }

        if (!aircraftMap.has(model)) {
          aircraftMap.set(model, { name: model });
        }

        aircraftMap.get(model).inspection = {
          daysRemaining: remainingDays,
          progress,
        };
      });

      // 비행 승인 데이터 처리
      flightAppvData.forEach((item) => {
        const model = item.model || 'Unknown';
        const expireDate = new Date(item.expire_date);
        const createdAt = new Date(item.createdAt);
        let totalDays = Math.round((expireDate - createdAt) / (1000 * 60 * 60 * 24));
        let remainingDays = Math.round((expireDate - new Date()) / (1000 * 60 * 60 * 24));
        let progress = Number((((totalDays - remainingDays) / totalDays) * 100).toFixed(1));

        if (expireDate.getFullYear()===1970)
        {
          totalDays =null; remainingDays=null; progress=0;
        }

        if (!aircraftMap.has(model)) {
          aircraftMap.set(model, { name: model });
        }

        aircraftMap.get(model).flightApproval = {
          daysRemaining: remainingDays,
          progress,
        };
      });

      // 비행 승인 데이터 처리
      flightAppvData.forEach((item) => {
        const model = item.model || 'Unknown';
        
        const expireDate = new Date(item.expire_date);
        const createdAt = new Date(item.createdAt);

        let   totalDays = Math.round((expireDate - createdAt) / (1000 * 60 * 60 * 24));
        let   remainingDays = Math.round((expireDate - new Date()) / (1000 * 60 * 60 * 24));
        let    progress = ((totalDays - remainingDays) / totalDays) * 100;

        if (expireDate.getFullYear()===1970)
        {
          totalDays =null; remainingDays=null; progress=0;
        }
        if (!aircraftMap.has(model)) {
          aircraftMap.set(model, { name: model });
        }

        aircraftMap.get(model).flightApproval = {
          daysRemaining: remainingDays,
          progress: progress.toFixed(1),
        };
      });

      // Map을 배열로 변환
      return Array.from(aircraftMap.values());
    },


    goToDetailedCalendar() {
      this.$router.push({ name: 'calendar' }); // 세부 일정 페이지로 이동하는 라우터 네임을 지정
    },

    fetchIndicators(){
      const config ={
        method: 'get',
        url: '/indicators',
        headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
      };
      axios(config)
      .then(response => {
          this.indicators = response.data;
        })
      .catch(error => {
          console.error('운영 지표를 가져오는 데 실패했습니다:', error);
        });
    },
    
    getUserInfo() {
        const config = {
            method: 'get',
            url: '/admins/me',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.token}`,
            },
        };
        axios(config)
            .then((res) => {
                this.userInfo = { ...res.data };
            })
            .catch(() => {
                this.$swal
                    .fire({ title: '정보없음', text: '로그아웃 되었습니다.', icon: 'error' })
                    .then(() => {
                        delete sessionStorage.token;
                        this.$router.replace({ path: '/gate' }).catch(() => true);
                    });
            });
    },
    
    formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토'];
        const dayOfWeek = daysOfWeek[date.getDay()];
        return `${year}.${month}.${day} (${dayOfWeek})`;
    },
    formatTime(date) {
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        const ampm = hours >= 12 ? '오후' : '오전';
        hours = hours % 12 || 12;
        return `${ampm} ${hours}:${minutes}:${seconds}`;
    },
    formatKoreanTime(isoTime) {
      if (!isoTime) return '';
      
      const date = new Date(isoTime);
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const period = hours < 12 ? '오전' : '오후';

      if (hours === 0) {
        hours = 12;
      } else if (hours > 12) {
        hours -= 12;
      }

      return `${period} ${hours}시${minutes > 0 ? ` ${minutes}분` : ''}`; // ✅ 오전 12시 or 오후 3시 30분
    },
    
    
}
  };
  </script>
  
  <style scoped>
.main-container {
  padding: 15px;
  background-color: rgb(255, 255, 255);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.logo-title {
  font-size: 1.0em;
  font-weight: bold;
  margin-bottom: 10px;
}

.logo-image {
  width: 1em;
  height: auto;
  margin-right: 4px;
}

.location-button {
  margin-left: 20px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.location-button:hover {
  background-color: #0056b3;
}

.weather-block {
  /* border: 2px solid #d3d3d3; */

  /* border-radius: 10px; */
  border: none;
  padding: 5 0px;
  background-color: white;
}

.weather-info {
  text-align: center;
  margin-bottom: 5px;
}

.weather-info h4 {
  margin-bottom: 10px;
}

.weather-info p {
  margin: 5px 0;
  text-align: center;
}

.date-time {
  display: flex;
  justify-content: space-between; /* 좌우 정렬 */
  border-bottom: 2px solid #c8cbcd; /* 언더라인 추가 */
  padding-bottom: 5px; /* 언더라인과 텍스트 사이 여백 */
  font-size: 1.5em; /* 원하는 크기로 조정 */
}

.date {
  text-align: left; /* 왼쪽 정렬 */
}

.time {
  text-align: right; /* 오른쪽 정렬 */
}

/* .clock {
  border-bottom: 2px solid #1d4876; 
} */
.vertical-divider {
  border-left: thin solid #c8cbcd;
  height: 140px;
  margin: 10px 10px;
}

.operation-alert {
  margin-top: 20px;
  /* width: 80%; 내용은 80% 너비 */
  margin: 0 auto; /* 양쪽 여백을 자동으로 */
}

/* .alert-title {
  text-align: left; 
  font-weight: bold;
  color: #1d4876;
  border-bottom: 2px solid #1d4876; 
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 100%; 
} */
.alert-title {
  border-bottom: 2px solid #1d4876;
  width: 98%;              /* ✅ 전체 폭 기준으로 넓게 */
  margin: 0 auto 10px;     /* ✅ 가운데 정렬 + 아래 여백 */
  padding-bottom: 5px;
}

.aircraft-col,
.other-col,
.other-col1,
.other-col2 {
  font-weight: bold;
  color: white;
  padding: 12px 0;             /* ✅ 높이 증가 */
  height: 50px;                /* ✅ 고정 높이 추가 */
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;         /* ✅ 수직 정렬 */
  justify-content: center;     /* ✅ 가운데 정렬 */
  font-size: 1.1em;
}


.aircraft-header-row {
  display: grid;
  grid-template-columns: 15% 1fr 1fr 1fr;
  column-gap: 20px; /* ✅ 메뉴 간 간격 추가 */
  /* margin-bottom: 15px;  */
  margin: 0 auto 15px;
  width: 99%;              /* ✅ 전체 너비 제한해서 줄이기 */
  max-width: 1200px;       /* ✅ 필요 시 최대 폭 제한 (선택 사항) */
}
.aircraft-name {
  /* width: 40%; */
  text-align: center;
  font-weight: bold; /* 글자 색상을 더 진하게 */
  color: #333; /* 진한 색상 */
  margin: 28px 0; /* 각 항목 사이의 여백 */
}
.horizontal-divider {
  /* width: 40px; */
  height: 2px;
  background-color: #ccc; /* 가로선 색상 */
  margin: 10px 0; /* 텍스트 사이의 여백 */
}
.alert-box {
  background-color: #f0f4f8;
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
}

.other-col {
  
  font-weight: bold;
  color: white;
  background-color: #709fb0;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
}

.other-col1 {
  
  font-weight: bold;
  color: white;
  background-color: #64775A;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
}
.other-col2 {
  
  font-weight: bold;
  color: white;
  background-color: #91936A;
  padding: 8px;
  border-radius: 5px;
  text-align: center;
}
.aircraft-col {
 
  /* width: 40%; */
  font-weight: bold;
  color: white;
  background-color: #a7d5e7;
  padding: 8px;
  border-radius: 5px;
  text-align: center; 
  
}

.mdb-row{
  display: grid;
  grid-template-columns: 15% 1fr 1fr 1fr; /* 첫 번째 열을 15%, 나머지를 균등 분배 */
  column-gap: 20px; /* 열 간의 간격 */  
  margin: 20px auto;
}
.progress-text {
  margin: 0;
  font-size: 15px; /* 텍스트 크기를 약간 줄임 */
  line-height: 1.3; /* 줄 높이를 줄여서 간격 조절 */
}
.progress-bar {
  height: 25px;
  width: 100%;
  margin-top: 5px;
}

.warning-text {
  color: red; /* D-14 이내일 때 글자를 빨간색으로 */
}
.warning-icon {
  width: 35px; /* 아이콘 크기 조정 */
  height: 35px;
  position: absolute; /* 아이콘을 텍스트 옆에 고정 */
  right: 10px; /* 텍스트 오른쪽에 고정된 위치에 표시 */
}

.operation-indicators, .calendar {
  
  margin: 20px auto;
  text-align: left; /* 왼쪽 정렬 */
}

.indicator-title {
  text-align: left; /* 왼쪽 정렬 */
  font-weight: bold;
  color: #1d4876;
  border-bottom: 2px solid #1d4876; /* 밑줄 추가 */
  padding-bottom: 5px;
  margin-bottom: 10px;
  width: 100%; /* 제목 100% 너비 */
}

.indicator-item {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; */
  display: flex;
  align-items: center; /* 아이콘과 텍스트를 세로 가운데 정렬 */
  justify-content: center; 
  gap: 10px; /* 아이콘과 텍스트 사이의 간격 */
}

.indicator-icon {
  width: 130px;
  height: 130px;
  margin-bottom: 10px;
}
.text-container {
  display: flex;
  flex-direction: column; /* 텍스트와 숫자를 수직으로 배치 */
}
.indicator-label {
  font-size: 1.5em;
  color: #333;  
  margin: 0;
}

.indicator-value {
  font-size: 2.5em; /* 큰 글자 크기 */
  font-weight: bold;
  color: #1d4876;
  margin: 0;
}
.calendar-container {
  /* max-width: 900px; 
  margin: 0 auto; */

  max-width: 100%; /* 최대 너비 */
  margin: 0 auto;
  font-size: 1.5em; /* 전체 글자 크기 */
}

.fc-daygrid-day-number {
  font-size: 1.5em !important; /* 날짜 숫자 크기 조절 */
}

/* 이벤트 텍스트와 배경색 설정 */
.fc-daygrid-event {
  color: white !important; /* 텍스트 색상을 흰색으로 설정 */
  font-size: 1.5em !important; /* 텍스트 크기 조절 */
  background-color: #007bff !important; /* 배경색을 더 진한 파란색으로 설정 */
  border: none !important; /* 테두리 제거 */
}

.aircraft-row {
  margin-bottom: 30px;
}

.aircraft-row-inner {
  display: grid;
  grid-template-columns: 15% 1fr 1fr 1fr;
  column-gap: 20px;
  align-items: start;
}

.aircraft-name {
  text-align: center;
  font-weight: bold;
  color: #333;
  margin: 28px 0;
}

.progress-text {
  margin: 0;
  font-size: 15px;
  line-height: 1.3;
}

.progress-bar {
  height: 25px;
  width: 100%;
  margin-top: 5px;
}

.warning-text {
  color: red;
}

.warning-icon {
  width: 35px;
  height: 35px;
  position: absolute;
  right: 10px;
}

.horizontal-divider {
  height: 2px;
  background-color: #ccc;
  margin: 10px 0;
}



.aircraft-grid {
  display: grid;
  grid-template-columns: 15% 1fr 1fr 1fr;
  row-gap: 10px;
  column-gap: 20px;
}

.aircraft-row {
  display: contents; /* ✅ grid-template-columns 유지하면서 한 줄씩 표현 */
}

.header-row .cell {
  font-weight: bold;
  color: white;
  padding: 12px 0;
  height: 50px;
  border-radius: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.aircraft-row .aircraft-name {
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

</style>
